import {
    BarChart,
    Dashboard,
    Home,
    Info,
    Phone,
    ScatterPlotOutlined,
    DesignServices,
    CreditScore,
    StackedLineChart, Chat, GroupAdd, Apps, Reviews, AutoAwesome, CalendarMonth, Assistant, PhotoFilter
} from "@mui/icons-material";
import logo2 from '../assets/CCS-box.png';
import AgentHrChatIndex from "./agents/agent-hr/agent-hr-chat-index";
import {useStripeGuard} from "../guards/stripe-guard";
import {useAwsGuard} from "../guards/aws-guard";
import {itemsGuard} from "../guards";
import {useTeamsGuard} from "../guards/teams-guard";
import {Avatar} from "@mui/material";
import logoWp from '../assets/Wordpress_Blue_logo.png';
import {useAwsPostAIGuard} from "../guards/aws-post-ai-guard";
import {usePostAiIntegrationsGuard} from "../guards/post-ai-integrations-guard";
/*const addAccess = (access) => {
    const r = [];
    if (access.content) {
        r.push()
    }
    if (access.copilotHr) {
        r.push()
    }
    if (access.smartGoals) {
        /** SMART GOALS option
        r.push()
    }
    return r;
}*/

const addAccess = (access) => {
    const r = [];
    if (access.content) {
        r.push({
            name: 'Content',
            link: true,
            routerLink: '/content',
            icon: <DesignServices/>
        })
    }
    if (access.copilotHr) {
        r.push({
            name: 'Chat HR',
            link: true,
            routerLink: '/chat-hr',
            icon: <Chat/>
        })
    }
    if (access.smartGoals) {
        /** SMART GOALS option*/
        r.push({
            name: 'Smart Goals',
            link: true,
            routerLink: '/smart-goals-hr',
            icon: <Reviews/>
        })
    }
    if (access.ccsHr) {
        /** CCS customer HR*/
        r.push({
            name: 'CCS Chat Hr',
            link: true,
            routerLink: '/chat-ccs-hr',
            icon: <Avatar src={logo2}/>
        })
    }
    return r;
}

export const isNormalPage = () => {
    const isInIframe = () => window.self !== window.top;
    console.log("ingress in; determineLoginContext")
    if(isInIframe()) {
        return false; //'external';   
    } else if (window.location.href.includes('app.mymoodbit') || window.location.href.includes('ngrok-free.app') || window.location.href.includes('localhost')) { // || window.location.href.includes('ngrok-free.app')
        return true; //'standard';
    } else {
        return true;//'none';
    }
};


export const adminItems = (user) => {
    let items = [
        // {
        //     name: 'Dashboard',
        //     link: true,
        //     routerLink: '/',
        //     icon: <Home/>
        // },
        // {
        //     name: 'PBI Reports',
        //     link: true,
        //     routerLink: '/pbi',
        //     icon: <ScatterPlotOutlined/>
        // },
        // {
        //     name: 'Analytics',
        //     link: true,
        //     routerLink: '/analytics',
        //     icon: <BarChart/>
        // },
        // {
        //     name: 'Contacts',
        //     link: true,
        //     routerLink: '/contacts',
        //     icon: <Phone/>
        // },
        // {
        //     name: 'About',
        //     link: true,
        //     routerLink: '/about',
        //     icon: <Info/>
        // },
        {
            name: 'Billing',
            link: true,
            routerLink: '/billing',
            icon: <CreditScore/>,
            guards: [useTeamsGuard],
            noPermissions: ['noPay']
        },
        {
            name: 'Integrations',
            link: true,
            routerLink: '/integrations',
            icon: <Apps/>,
            // noPermissions: ['noPay']
            // orGuards: [useStripeGuard, useAwsPostAIGuard]
        },
        {
            name: 'Content',
            link: true,
            routerLink: '/content',
            icon: <DesignServices/>,
            guards: [useStripeGuard],
            permissions: ['content']
        },
        {
            name: 'Chat HR',
            link: true,
            routerLink: '/chat-hr',
            icon: <Assistant/>,
            //TO-DO: return aws back if needed, (stripe OR aws) AND (not teams)
            orGuards: [useStripeGuard, useAwsGuard],
            // orGuards: [useStripeGuard, useAwsGuard, useTeamsGuard],
            // orGuards: [useStripeGuard, useTeamsGuard],
            permissions: ['copilotHr']
        },
        {
            name: 'Chat HR Dev',
            link: true,
            routerLink: '/chat-hr-test',
            icon: <Chat/>,
            //TO-DO: return aws back if needed, (stripe OR aws) AND (not teams)
            // orGuards: [useStripeGuard, useAwsGuard, useTeamsGuard],
            orGuards: [useStripeGuard, useTeamsGuard],
            permissions: ['copilotHrTest']
        },
        {
            name: 'Smart Goals',
            link: true,
            routerLink: '/smart-goals-hr',
            icon: <Reviews/>,
            guards: [useStripeGuard],
            permissions: ['smartGoals']
        },
        {
            name: 'Goals/Feedback Live',
            link: true,
            routerLink: '/smart-goals-live-hr',
            icon: <PhotoFilter/>,
            guards: [useStripeGuard],
            permissions: ['smartGoals']
        },
        {
            name: 'CCS',
            link: true,
            routerLink: '/chat-ccs-main-hr',
            icon: <Avatar src={logo2}/>,
            // guards: [useStripeGuard],
            permissions: ['ccsMainHr']
        },
        {
            name: 'CCS Chat Hr',
            link: true,
            routerLink: '/chat-ccs-hr',
            icon: <Avatar src={logo2}/>,
            guards: [useStripeGuard],
            permissions: ['ccsHr']
        },
        {
            name: 'Post AI',
            link: true,
            routerLink: '/stored-post',
            // icon: <Avatar src={logoWp}/>
            icon: <AutoAwesome/>,
            //TO-DO: return aws back if needed, (stripe OR aws) AND (not teams)
            orGuards: [useStripeGuard, useAwsPostAIGuard],
            // guards: [usePostAiIntegrationsGuard],
            // guards: [useStripeGuard, useTeamsGuard],
            // permissions: ['copilotHr']
        },
        {
            name: 'Post AI Month',
            link: true,
            routerLink: '/post-month',
            icon: <CalendarMonth/>,
            orGuards: [useStripeGuard, useAwsPostAIGuard],
        }
    ]
    // items = [...items, ...addAccess(user.copilotAccess || {})]
    items = itemsGuard(user, items);
    return items;
}
