import React, { useEffect, useState } from "react";
import { Chip, CircularProgress, Tooltip, Badge} from "@mui/material"; // Reutilizando componentes de MUI como en LinkedIn
import { setNewFacebookIntegrationService, disconnectFacebookIntegrationService} from '../../services/agent-post-ai.service';
import { getUserWithEmailThunk } from "../../redux/users/users.thunk";
import { connect } from "react-redux";

const CustomChip = (props) => {
  return (
    <Chip
      variant="filled"
      disabled={props.status === "loading"}
      avatar={
        props.status === "loading" ? (
          <CircularProgress size={20} />
        ) : (
          <img
            style={{ width: "35px", height: "35px" }}
            src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
            alt="Facebook Logo"
          />
        )
      }
      label="Facebook"
      {...props}
    />
  );
};

const FacebookLoginButtonTest = (props) => {
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState("unknown");
  const [isSdkLoaded, setIsSdkLoaded] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [integrated, setIntegrated] = useState(false);

  useEffect(() => {
    const integrateFacebook = async () => {
      if (user) {
        await setNewFacebookIntegrationService({
          ...user,
          analyticsUserId: props.user?.id,
        });
        setIntegrated(true);
        props.getUser(props.user.email);
      }
    };
    if (!integrated) {
      integrateFacebook();
    }
  }, [user]);

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "1029802844925790",
        cookie: true,
        xfbml: true,
        version: "v20.0",
      });

      window.FB.AppEvents.logPageView();
      console.log("PageView logged");

      window.FB.getLoginStatus(function (response) {
        console.log("FB.getLoginStatus called");
        statusChangeCallback(response);
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        console.log("SDK already loaded");
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
      console.log("SDK script inserted");
    })(document, "script", "facebook-jssdk");
    insertScriptManually();
  }, []);

  const statusChangeCallback = (response) => {
    setStatus(response.status);
    console.log("statusChangeCallback called");
    console.log("Response:", response);

    if (response.status === "connected") {
      console.log("User is connected");
      fetchUserData(response.authResponse);
      setIsLogin(true);

      const { accessToken, expiresIn } = response.authResponse;
      const expirationTime = new Date().getTime() + expiresIn * 1000;
      const tokenData = {
        accessToken,
        expirationTime,
        used: false,
      };
      localStorage.setItem("facebookAuthToken", JSON.stringify(tokenData));
    } else {
      setUser(null);
    }
  };

  const fetchUserData = (authResponse) => {
    // Guardar el token de acceso en localStorage
    localStorage.setItem("facebookAccessToken", authResponse.accessToken);

    // Obtener los datos del usuario
    window.FB.api("/me", { fields: "name,email,picture" }, function (response) {
      console.log("FB User", response);
      let obj = {
        name: response.name,
        email: response.email,
        picture: response.picture.data.url,
        accessToken: authResponse.accessToken,
      };

      // Ahora obtener las páginas administradas por el usuario
      window.FB.api("/me/accounts", function (pageResponse) {
        if (pageResponse && pageResponse.data) {
          const pagesData = pageResponse.data.map((page) => ({
            id: page.id,
            name: page.name,
          }));

          // Guardar las páginas en localStorage
          localStorage.setItem("facebookPages", JSON.stringify(pagesData));
          obj = { ...obj, pages: pagesData };
          setUser(obj);

          console.log("Páginas guardadas en localStorage:", pagesData);
        }
      });
    });
  };

  const insertScriptManually = () => {
    setIsSdkLoaded(true);
    if (!document.getElementById("facebook-jssdk-manual")) {
      const script = document.createElement("script");
      script.id = "facebook-jssdk-manual";
      script.src = "//connect.facebook.net/en_GB/sdk.js";
      document.head.appendChild(script);

      script.onload = () => {
        window.FB.init({
          appId: "1029802844925790",
          cookie: true,
          xfbml: true,
          version: "v20.0",
        });
        window.FB.getLoginStatus(function (response) {
          statusChangeCallback(response);
        });
      };
    }
  };

  const existFacebookAccount = () => {
    return  props.user.analytics_facebook_integrations !== null && props.user.analytics_facebook_integrations?.length > 0;
  };

  const handleLogin = () => {
    console.log("Login button clicked");
    window.FB.login(
      function (response) {
        console.log("FB.login response:", response);
        props.getUser(props.user.email);
        statusChangeCallback(response);
      },
      {
        scope:
          "public_profile,email,pages_manage_posts,pages_show_list,business_management",
        auth_type: "rerequest",
        return_scopes: true,
        enable_profile_selector: true,
        config_id: "3401875730106723",
      }
    );
  };

  const handleLogout = () => {
    window.FB.logout(function (response) {
      console.log("User logged out:", response);
      setUser(null);
      setStatus("unknown");
      setIsLogin(false);
    });
  };

  const disconnect = async () => {
          if (window.confirm(`Are you sure you want to disconnect your Facebook account ${props.user.analytics_facebook_integrations[0]['name']}?`)) {
              setStatus("loading")
              await disconnectFacebookIntegrationService(props.user.id);
              await props.getUser(props.user.email);
              setStatus("loaded")
          }
        }

  return (
    <Tooltip
      title={
        existFacebookAccount()
          ? props.user.analytics_facebook_integrations[0]["name"]
          : ""
      }
      className="w-100"
    >
      <Badge color="success" variant="dot" invisible={!existFacebookAccount()}>
        {existFacebookAccount() ? (
          <CustomChip
            label={<small className="text-black-50">Connected</small>}
            className="py-4 w-100 justify-content-center"
            onClick={() => {}}
            onDelete={() => disconnect()}
            status={status}
          />
        ) : (
          <CustomChip
            className="py-4 w-100"
            onClick={() => handleLogin()}
            status={status}
          />
        )}
      </Badge>
    </Tooltip>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.users.user,
    status: state.users.status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (email) => dispatch(getUserWithEmailThunk(email)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FacebookLoginButtonTest);
