import { Navigate, useNavigate, useRoutes } from 'react-router-dom'
import HomePage from './components/home/HomePage'
import Dashboard from './components/dashboard/dashboard'
import ReportPBI from './components/PBI/ReportPBI'
import FormContent from './components/content/Form'
import Billing from './components/billing/billing-stripe-index'
import { connect } from 'react-redux'
import { addUserThunk, getUserThunk } from './redux/users/users.thunk'
import UserProfile from './components/user/user-profile'
import { useTranslation } from 'react-i18next'
import BillingContact from './components/billing/billing-contact'
import BillingContactIndex from './components/billing/billing-contact-index'
import DialogContact from './components/billing/dialog-contact/dialog-contact'
import CountIndex from './components/content/counts/count-index'
import AgentHrChatIndex from './components/agents/agent-hr-new/agent-hr-chat-index'
import PbiQaIndex from "./components/agents/agent-hr/components/pbi-qa/pbi-qa-index";
import IntegrationIndex from "./components/integrations/integration-index";
import SmartGoalsIndex from "./components/agents/smart-goals/smart-goals-index";
import {useStripeGuard} from "./guards/stripe-guard";
import {useRoutesGuard} from "./guards";
import {useAwsGuard} from "./guards/aws-guard";
import BillingIndex from "./components/billing/billing-index";
import {useTeamsGuard} from "./guards/teams-guard";
import AgentHrCcsChatIndex from "./components/agents/agent-ccs-hr/agent-hr-ccs-chat-index";
import AgentCcsMainHrChatIndex from "./components/agents/agent-ccs-main-hr/agent-ccs-main-hr-chat-index";
import {determineLoginContext, useTeamsFx} from "./azure_auth/lib/useTeamsFx";
import WordpressLogin from "./components/integrations/wordpress/wordpress-login";
import Chat from "./components/agents/agent-post/Chat";
import {useAwsPostAIGuard} from "./guards/aws-post-ai-guard";
import StoredPost from "./components/agents/agent-post/StoredPosts"
import WordpressLoginFull from "./components/integrations/wordpress/wordpress-login-full";
import {usePostAiIntegrationsGuard} from "./guards/post-ai-integrations-guard";
import WordpressLoginPlugin from "./components/integrations/wordpress/wordpress-login-plugin";
import AgentPostMonthIndex from "./components/agents/agent-post-month/agent-post-month-index";
import PostMonthCreate from "./components/agents/agent-post-month/post-month-create/post-month-create";
import PostMonthShow from "./components/agents/agent-post-month/post-month-show/post-month-show";
import {useSlackDatachatGuard} from "./guards/slack-datachat-guard";
import {useTeamsDatachatGuard} from "./guards/teams-datachat-guard";
import {useTeamsPostAIGuard} from "./guards/teams-postai-guard";
import RootRoute from "./components/root-route/root-route";
import AgentHrChatTestIndex from "./components/agents/agent-hr/agent-hr-chat-index";
import SmartGoalsLiveIndexModule from "./components/agents/smart-goals-live/smart-goals-live-index";

const AppRouting = (props) => {
  console.log('ROUTING ', props)
  const isSubscribed = props.user && props.user.stripe_live_subscriptions_analytics && props.user.stripe_live_subscriptions_analytics.length > 0
  const teamsContext = useTeamsFx();
  // const awsSubscription = user?.all_aws_live_subscriptions;
  // console.log("AWS AWS", awsSubscription)
  const isTeams = determineLoginContext(teamsContext) !== 'standard';
  console.log("IS TEAMS ", isTeams)
  const { t } = useTranslation()
  let routes = [
      // //if on the web (useTeamsGuard), alternate components - billing if no sub, chathr if paid, on teams - integrations
      {
          path: '/',
          // element: isTeams ? <IntegrationIndex/> : (isSubscribed ? <AgentHrChatIndex/> : <BillingIndex/>) , // TODO commented temporally for teams approve
          element: <RootRoute/>,
          // element: <BillingIndex/>,
          noPermissions: ['noPay'], // for CCS users to redirect to the REDIRECT field
          redirect: '/chat-ccs-main-hr'
          // element: <Billing t={t} />,
      },
      //remove ONA views for the first launch
      // {
      //     path: '/',
      //     element: <HomePage open={props.open} />
      // },
      // {
      //     path: '/pbi',
      //     element: <ReportPBI />
      // },
      {
        path: '/billing',
        element: <BillingIndex/>,
        guards: [useTeamsGuard],
        noPermissions: ['noPay']
      },
      {
        path: '/billing-contact',
        element: <BillingContactIndex/>,
        // guards: [useStripeGuard]
      },
      {
        path: 'user-profile',
        element: <UserProfile t={t}/>,
        guards: []
      },
      {
        path: '/contact-sales',
        element: <DialogContact t={t}/>,
        // guards: [useStripeGuard]
      },
      {
        path: '/integrations',
        element: <IntegrationIndex/>,
        orGuards: [useStripeGuard, useAwsGuard, useAwsPostAIGuard, useSlackDatachatGuard, useTeamsDatachatGuard, useTeamsPostAIGuard],
        // noPermissions: ['noPay']
        // orGuards: [useStripeGuard, useAwsPostAIGuard]
      },
      {
        path: '/content',
        element: <FormContent tenantId={props.user?.microsoftTenantId}/>,
        guards: [useStripeGuard],
        // orGuards: [useStripeGuard],
        permissions: ['content']
      },
      {
          path: '/chat-hr-test',
          element: <AgentHrChatTestIndex/>, // <AgentHrChatIndex/>,
          orGuards: [useStripeGuard, useAwsGuard],
          permissions: ['copilotHrTest']
      },
      {
        path: '/chat-hr',
        element: <AgentHrChatIndex/>, // <AgentHrChatIndex/>,
        orGuards: [useStripeGuard, useAwsGuard],
        permissions: ['copilotHr']
      },
      {
          path: '/chat-hr/:conversationId',
          element: <AgentHrChatIndex/>,
          orGuards: [useStripeGuard, useAwsGuard],
          permissions: ['copilotHr']
      },
      /*{
        path: '/chat-hr/:conversationId',
        element: <AgentCcsMainHrChatIndex/>,
        // guards: [useStripeGuard],
        permissions: ['copilotHr']
      },*/
      {
        path: '/smart-goals-hr',
        element: <SmartGoalsIndex/>,
        guards: [useStripeGuard],
        permissions: ['smartGoals']
      },
      {
        path: '/smart-goals-live-hr',
        element: <SmartGoalsLiveIndexModule/>,
        guards: [useStripeGuard],
        permissions: ['smartGoals']
      },

      // report pbi-copilot
      {
        path: '/chat-ccs-hr',
        element: <AgentHrCcsChatIndex/>,
        guards: [useStripeGuard],
        permissions: ['ccsHr']
      },
      {
          path: '/chat-ccs-main-hr',
          element: <AgentCcsMainHrChatIndex/>,
          // guards: [useStripeGuard],
          permissions: ['ccsMainHr']
      },
      {
        path: '/chat-ccs-main-hr/:conversationId',
        element: <AgentCcsMainHrChatIndex/>,
        // guards: [useStripeGuard],
        permissions: ['ccsMainHr']
      },
      {
          path: '/integrations/wordpress-login',
          // element: <WordpressLogin/>,
          element: <WordpressLoginPlugin/>,
          orGuards: [useStripeGuard, useAwsPostAIGuard],
          // permissions: ['ccsMainHr']
      },
      {
          path: '/integrations/wordpress-login-full',
          element: <WordpressLoginFull/>,
          orGuards: [useStripeGuard, useAwsPostAIGuard],
          // permissions: ['ccsMainHr']
      },
      {
          path: '/chat-agpost',
          element: <Chat/>,
          orGuards: [useStripeGuard, useAwsPostAIGuard],
          // guards: [usePostAiIntegrationsGuard],
          // permissions: ['copilotHr']
      },
      {
        path: '/stored-post',
        element: <StoredPost/>,
        orGuards: [useStripeGuard, useAwsPostAIGuard],
        // guards: [useStripeGuard],
        //permissions: ['ccsMainHr']
      },
      {
          path: '/post-month',
          element: <AgentPostMonthIndex/>,
          orGuards: [useStripeGuard, useAwsPostAIGuard],
          // guards: [usePostAiIntegrationsGuard],
          // permissions: ['copilotHr']
      },
      {
          path: '/post-month/:plan_id',
          element: <AgentPostMonthIndex/>,
          orGuards: [useStripeGuard, useAwsPostAIGuard],
          // guards: [usePostAiIntegrationsGuard],
          // permissions: ['copilotHr']
      },
      {
          path: '/new-post-month',
          element: <PostMonthCreate/>,
          orGuards: [useStripeGuard, useAwsPostAIGuard],
          // guards: [usePostAiIntegrationsGuard],
          // permissions: ['copilotHr']
      },
      {
          path: '/show-post-month/:plan_id',
          element: <PostMonthShow/>,
          orGuards: [useStripeGuard, useAwsPostAIGuard],
          // guards: [usePostAiIntegrationsGuard],
          // permissions: ['copilotHr']
      },
      {
        path: '*',
        element: <Navigate to="/" />,
      },
    ]

  // const tempRoutes = addAccess(props, props.user?.copilotAccess || {})
  // routes = [...routes, ...tempRoutes];

  // return useRoutes(routes)
  return useRoutesGuard(routes);
}

const mapStateToProps = (state) => {
  console.log('state app:', state)
  return {
    user: state.users.user,
    status: state.users.status,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (id) => dispatch(getUserThunk(id)),
    addUser: (obj) => dispatch(addUserThunk(obj)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppRouting)
