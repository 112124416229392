import React, { useState, useEffect } from 'react';
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { UnauthenticatedTemplate } from '@azure/msal-react';
import RotatingSpinner from '../components/layouts/RotatingSpinner';
import { loginRequest } from './authConfig';
import * as microsoftTeams from "@microsoft/teams-js";
import { determineLoginContext, useTeamsFx } from "./lib/useTeamsFx";
import { Button, Typography } from "@mui/material";

const Unauthenticated = () => {
    const { instance, accounts, inProgress } = useMsal();
    const teamsContext = useTeamsFx();
    const [teamsInitialized, setTeamsInitialized] = useState(false);
    const [accessToken, setAccessToken] = useState("");
    const [error, setError] = useState("");

    console.log("-- 2  instance: ", instance.getAllAccounts())

    useEffect(() => {
        if (isStandardLogin() && !accounts.length) {
            signInStandard();
        }
    }, [accounts]);

    useEffect(() => {
        microsoftTeams.initialize(() => {
            setTeamsInitialized(true);
            console.log("Teams SDK initialized.");
        });
    }, []);
    useEffect(() => {

    }, [instance]);
    const callMsGraph = async (accessToken) => {
        const endpoint = "https://graph.microsoft.com/v1.0/me";
        const headers = new Headers();
        headers.append("Authorization", `Bearer ${accessToken}`);

        const options = {
            method: "GET",
            headers: headers
        };

        const response = await fetch(endpoint, options);
        const data = await response.json();
        return data;
    };
    const getUserProfile = async (token) => {
        console.log("Fetching user profile with accessToken: ", token);
        console.log("-- instance: ", instance.getAllAccounts())
        try {
            const userDetails = await callMsGraph(token);
            console.log("User Details from Graph: ", userDetails);
            if (accounts.length == 0) {

            }
            //setUserDetails(userDetails); 
        } catch (error) {
            console.error("Failed to fetch user profile from Graph:", error);
            setError("Failed to fetch user profile: " + error.message);
        }
    };

    useEffect(() => {
        console.log("------->> accounts 2: ", accounts)
        console.log("------->> accessToken: ", accessToken)
        if (accessToken) {
            console.log("AccessToken updated, fetching user profile...");
            getUserProfile(accessToken);
        }
    }, [accounts, accessToken]);

    const signInTeams = () => {
        if (!teamsContext.isInTeams) {
            console.log("Not in Teams context or already authenticated.");
            return;
        }
        microsoftTeams.authentication.authenticate({
            url: window.location.origin + "/auth-start.html",
            width: 600,
            height: 535,
            successCallback: (token) => {
                console.log("Authenticated in Teams successfully principal", token);
                setAccessToken(token);
                try {
                    instance.ssoSilent({ loginHint: teamsContext.context.loginHint })
                    console.log("------- context.loginHint: ", teamsContext.context.loginHint)
                } catch (e) {
                    console.log("error in instance.ssoSilent({loginHint: teamsContext.context.loginHint})")
                }
            },
            failureCallback: (reason) => {
                console.error("Authentication failed in Teams:", reason);
                setError("Authentication failed: " + reason);
            }
        });
    };

    const signInStandard = () => {
        console.log("Attempting standard login...");
        instance.loginRedirect(loginRequest).catch(error => {
            console.error("Failed to redirect to login:", error);
        });
        /*instance.loginPopup(loginRequest).catch(error=>{
            console.log("fail in login with popup int unauthenticated",error)
        })*/
    };
    const signInExternalServices = () => {
        console.log("Attempting login for external services...");
        instance.loginPopup(loginRequest).catch(error => {
            console.error("Failed to redirect to login:", error);
        });
    };
    const isInIframe = () => window.self !== window.top;

    const renderLoginButton = () => {
        const context = determineLoginContext(teamsContext);
        console.log("render login button is: ", context)
        switch (context) {
            case 'teams':
                return (
                    <Button variant="outlined" color="secondary" size="large" onClick={signInTeams}>
                        Teams Login
                    </Button>
                );
            case 'external':
                return (
                    <Button variant="outlined" color="primary" size="large" onClick={signInExternalServices}>
                        Data Chat Login
                    </Button>
                );
            default:
                return null;
        }
    };
    function isStandardLogin() {
        const context = determineLoginContext(teamsContext);
        console.log("render login button is: ", context);
        return context === 'standard';
    }

    console.log("------->> accounts 3: ", determineLoginContext(teamsContext))
    console.log("------->> accounts: ", accounts)
    console.log(" is logged in teams? : ", teamsContext.isInTeams)
    function isTeamsTab() {
        //console.log('NAVIGATION', window.navigator.appVersion);
        //if (window.navigator.appVersion.includes("Teams")) {
        console.log('CTX TEAMS', teamsContext.context);
        const info = window.location.href;
        console.log("my info url is: ", info)
        if (teamsContext.context !== undefined) {
            return true;
        } else
            return false;

    }

    console.log(" is logged in teams tab? v4: ", isTeamsTab())

    return (
        <>
            <AuthenticatedTemplate>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                {inProgress !== 'none' ? (
                    <div className="text-center">
                        <p className="text-capitalize">{`${inProgress} in progress...`}</p>
                        <RotatingSpinner />
                    </div>
                ) : (
                    <div className="row h-100 mt-5">
                        <div className="row h-100 mt-5">
                            {!isStandardLogin() && renderLoginButton()}
                        </div></div>
                )}
            </UnauthenticatedTemplate>
        </>
    );
};

export default Unauthenticated;
