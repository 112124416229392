import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Chip, CircularProgress} from "@mui/material";
import TextFieldWrapper from "../../layouts/forms/text-field-wrapper";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {generateContentService, saveCountTokensWordsService} from "../../../services/content.service";
import {toastr} from "react-redux-toastr";
import {countWords} from "../../../helpers/text";
import ButtonWrapper from "../../layouts/forms/button-wrapper";
import {
    setNewWordpressIntegrationService,
    validateWordpressBlogUrlService
} from "../../../services/agent-post-ai.service";
import {
    deleteDriveUserThunk,
    getUserWithEmailThunk,
    updateDriveUserThunk,
    updateIndexUserThunk
} from "../../../redux/users/users.thunk";
import {connect} from "react-redux";
import {useNavigate} from "react-router-dom";

const steps = ['Install Plugin', 'Connect Wordpress'];

const WordpressLoginPlugin = (props) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [loading, setLoading] = React.useState(false);
    const [ref, setRef] = React.useState({});
    const navigate = useNavigate();

    const isStepOptional = (step) => {
        return step === -1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const INITIAL_FROM_STATE = {
        blogUrl: '',
        username: '',
        password: '',
    };

    const FORM_VALIDATION = Yup.object().shape({
        blogUrl: Yup.string().url('Blog URL must be a valid url').required('Blog URL is required'),
        username: Yup.mixed().required('Username is required'),
        password: Yup.mixed().required('Password is required'),
    });

    const onSubmitForm = async (values) => {
        // setContent("Generating...")
        // setLoading(true);

        // toastr.success('', t('content.create.success'));
        setLoading(true)
        const verification = await validateWordpressBlogUrlService(values)
        console.log("VERIFY", verification)
        if (verification?.validate) {
            await setNewWordpressIntegrationService({...values, analyticsUserId: props.user.id})
            props.getUser(props.user.email);
            // toastr.error("Success", verification?.message)
            navigate('/integrations')
        } else {
            toastr.error("Error", verification?.message)
        }
        setLoading(false)
    }

    return (
        <div className="row mt-3">
            <div className="col-md-8 offset-md-2">
                <Box sx={{width: '100%'}}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepOptional(index)) {
                                labelProps.optional = (
                                    <Typography variant="caption">Optional</Typography>
                                );
                            }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {activeStep === steps.length ? (
                        <React.Fragment>
                            <Typography sx={{mt: 2, mb: 1}}>
                                All steps completed - you&apos;re finished
                            </Typography>
                            <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                <Box sx={{flex: '1 1 auto'}}/>
                                <Button onClick={handleReset}>Reset</Button>
                            </Box>
                        </React.Fragment>
                    ) : (
                        activeStep === 0 ?
                            <React.Fragment>
                                <Typography sx={{mt: 2, mb: 1}}>
                                    {/*First you need to install the <a href="https://github.com/WP-API/Basic-Auth"
                                                                     target="_blank">Moodbit Wordpress Plugin</a> plugin on your Wordpress account, once you complete this step you
                                    can move on to the next one.*/}
                                    {/*First you need to install the <b>Moodbit Wordpress Plugin</b> on your Wordpress account, to do that, click <a href="https://wpplugin.blob.core.windows.net/plugin/moodbit-agent.zip" target="_blank">here</a> to download the plugin and then install it in your Wordpress account.*/}
                                    First you need to install the <b>Moodbit Wordpress Plugin</b> on your Wordpress account, to do that, click <a href="https://wordpress.org/plugins/moodbit-agent/" target="_blank">here</a> to download the plugin and then install it in your Wordpress account.
                                    <br/>
                                    Once you complete this step you can move on to the next one.
                                </Typography>
                                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                    <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{mr: 1}}
                                    >
                                        Back
                                    </Button>
                                    <Box sx={{flex: '1 1 auto'}}/>
                                    {isStepOptional(activeStep) && (
                                        <Button color="inherit" onClick={handleSkip} sx={{mr: 1}}>
                                            Skip
                                        </Button>
                                    )}

                                    <Button onClick={handleNext}>
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button>
                                </Box>
                            </React.Fragment> :
                            <React.Fragment>
                                <Formik
                                    innerRef={(fa) => fa ? setRef(fa.values) : setRef({})}
                                    initialValues={INITIAL_FROM_STATE}
                                    validationSchema={FORM_VALIDATION}
                                    onSubmit={values => onSubmitForm(values)}>
                                    <Form>
                                        <div className="col-md-12 grid-margin stretch-card">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="form-group my-2">
                                                        <TextFieldWrapper name="blogUrl"
                                                                          label="Blog URL"
                                                                          type="text"/>
                                                    </div>
                                                    <div className="form-group my-2">
                                                        <TextFieldWrapper name="username"
                                                                          label="Username"
                                                                          type="text"/>
                                                    </div>
                                                    <div className="form-group my-2">
                                                        <TextFieldWrapper name="password"
                                                                          label="Password"
                                                                          type="password"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-center mb-3">
                                            {loading ?
                                                <CircularProgress size={20}/>
                                                :<ButtonWrapper>Connect</ButtonWrapper>}
                                        </div>
                                    </Form>
                                </Formik>
                                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                                    <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{mr: 1}}
                                    >
                                        Back
                                    </Button>
                                    <Box sx={{flex: '1 1 auto'}}/>
                                    {isStepOptional(activeStep) && (
                                        <Button color="inherit" onClick={handleSkip} sx={{mr: 1}}>
                                            Skip
                                        </Button>
                                    )}
                                    {activeStep < steps.length - 1 &&
                                        <Button onClick={handleNext}>
                                            {'Next'}
                                        </Button>}
                                </Box>
                            </React.Fragment>
                    )}
                </Box>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    console.log('state app:', state);
    return {
        user: state.users.user,
        status: state.users.status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (email) => dispatch(getUserWithEmailThunk(email)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WordpressLoginPlugin);
