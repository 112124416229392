import { useEffect, useRef, useState } from 'react'
import Typed from 'react-typed'
import {
    getFollowup,
    getInsight,
    setChatHrOnedriveService,
    setChatHrGoogledriveService,
    setChatHrService,
    storeConversationMessage,
    setSmartGoalsService,
    setFeedbackAssistantService,
    setSmartGoalsShortService, setChatSapTimeService,
} from '../../../services/agent-hr.service'
import { refreshTokenGoogleDriveService } from '../../../services/drive-hr.service'
import { updateIndexDriveService } from "../../../services/user.service";
import { toastr } from "react-redux-toastr";
import { AnalysisPanelTabs, Approaches } from '../models'
import styles from './smart-goals-index.module.css'
import { ClearChatButton } from './components/clear-chat-button/clear-chat-button'
import ExampleList from './components/example/example-list'
import UserChatMessage from './components/user-chat-message/user-chat-message'
import Answer from './components/answer/answer'
import AnswerLoading from './components/answer/answer-loading'
import { AnswerError } from './components/answer/answer-error'
import { QuestionInput } from './components/question-input/question-input'
import { AnalysisPanel } from './components/analysis-panel/analysis-panel'
import {
    Checkbox,
    DefaultButton,
    Panel,
    SpinButton,
    TextField,
} from '@fluentui/react'
import {
    addUserThunk,
    editUserThunk,
    getUserWithEmailThunk,
} from '../../../redux/users/users.thunk'
import { updateCountry } from '../../../redux/users/users.actions'
import { connect } from 'react-redux'
import { SendEmailButton } from './components/send-email-button/send-email-button'
import { Button, IconButton } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import SelectIndex from "./components/select-index/selet-index";
import { setContainer, setCopilotTheme, setIndex } from "../../../redux/agent-hr/agent-hr.actions";
import PbiQaIndex from "./components/pbi-qa/pbi-qa-index";

const SmartGoalsIndex = (props) => {
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false)
    const [promptTemplate, setPromptTemplate] = useState('')
    const [retrieveCount, setRetrieveCount] = useState(10)
    const [useSemanticRanker, setUseSemanticRanker] = useState(false)
    const [useSemanticCaptions, setUseSemanticCaptions] = useState(false)
    const [excludeCategory, setExcludeCategory] = useState('')
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] =
        useState(true)

    const lastQuestionRef = useRef('')
    const chatMessageStreamEnd = useRef(null)

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingInsight, setIsLoadingInsight] = useState(false)
    const [isLoadingFollowup, setIsLoadingFollowup] = useState(false)
    const [error, setError] = useState(null)

    const [activeCitation, setActiveCitation] = useState()
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] =
        useState(undefined)

    const [selectedAnswer, setSelectedAnswer] = useState(0)
    const [answers, setAnswers] = useState([])
    const [route, setRoute] = useState("DEFAULT")

    const [language, setLanguage] = useState('English')
    const [hrId, setHrId] = useState(null)
    const [init, setInit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [fileName, setFileName] = useState(null)

    // const [indexName, setIndexName] = useState(props.user?.whitelisted ? 'hr-index' : 'empty-data')

    const [startComponent, setStartComponent] = useState(null)

    const [typing, setTyping] = useState(false)
    const location = useLocation()
    const contentRef = useRef(null);

    // for show pbi Q&A
    const [showQa, setShowQa] = useState(false)
    const [currentMessageQa, setCurrentMessageQa] = useState(null)

    // for google drive
    const [gdToken, setGdToken] = useState({}); // only for development

    useEffect(() => {
        setStartComponent(location.pathname)
        goBack(false)

        props.setIndexName(props.user?.whitelisted ? props.user.indexList?.length > 0 ? props.user.indexList[0].value : 'empty-data' : 'empty-data')
        props.setContainer('content-hr')

        window.addEventListener('message', handlerGoogleCallbackMessage); // add event listener
        return () => {
            window.removeEventListener('message', handlerGoogleCallbackMessage); // remove event listener
        };
    }, [location])

    // console.log('START', startComponent, location);

    const storeMessage = async (msg) => {
        const data = await storeConversationMessage({
            message: msg,
            hrId,
            type: 'store-messages',
        })
        console.log(data)
    }

    const readInsightsStream = async (answer, question, result, followup) => {

        const response = await getInsight(answer);
        setIsLoadingInsight(false);
        // console.log("IS STREAM", response)
        const reader = response.body?.getReader();
        const decoder = new TextDecoder();

        let completeResponse = "";

        while (true) {
            // setTyping(true);
            /*console.log("SCROLLLL", contentRef.current.scrollTop, contentRef.current.scrollHeight);
            if (contentRef.current.scrollTop === contentRef.current.scrollHeight) {

            }*/
            // contentRef.current.scrollIntoView({behavior: 'smooth', block: "end"})
            // contentRef.current.scrollTop = contentRef.current.scrollHeight;
            const { value, done: doneReading } = await reader.read();
            if (doneReading) break;
            let chunkValue = decoder.decode(value);
            completeResponse += chunkValue;

            setAnswers([...answers, [question, { ...result, containerName: props.containerName, insight: completeResponse, answer: result.answer, followup: followup, finishInsight: false }]])
        }

        setAnswers([...answers, [question, { ...result, containerName: props.containerName, insight: completeResponse, answer: result.answer, followup: followup, finishInsight: true }]])
    }

    const newFollowup = async (question) => {
        console.log("FOOOLLO")
        setAnswers(answers.map((item, index) => {
            if (index === answers.length - 1) {
                item[1].followup = null;
            }
            return item;
        }))
        const history = answers.map((a) => ({ user: a[0], bot: a[1].answer, sql_bot: a[1].sqlQuery }))

        setIsLoadingFollowup(true);
        const { followup } = await getFollowup([...history, { user: question, bot: undefined }], props.indexName);
        setIsLoadingFollowup(false);

        console.log("FOLLOWUP", followup);

        setAnswers(answers.map((item, index) => {
            if (index === answers.length - 1) {
                item[1].followup = followup;
            }
            return item;
        }))
    }

    const openGoogleOauthPopUp = (force) => {
        // open the consent screen in a pop up
        if (force)
            window.open(`${process.env.REACT_APP_LOGIN_GOOGLEDRIVE}/api/get-token?force=true&location=${window.location.origin}`, '_blank', 'width=500,height=500');
        else
            window.open(`${process.env.REACT_APP_LOGIN_GOOGLEDRIVE}/api/get-token?location=${window.location.origin}`, '_blank', 'width=500,height=500');
    }

    let handlerGoogleCallbackMessage = async (event) => {
        try {
            // TODO control the case when the endpoint dont return the token
            if (event.origin != `${process.env.REACT_APP_LOGIN_GOOGLEDRIVE}`) {
                return; // ignore from another origin
            }
            let data = event.data;
            console.log('get data from tunnel', data);
            let index_info_update = {
                "index": {
                    "collection_id": data.email,
                    "email": data.email,
                    "token": data.token,
                    "exp_date": data.expiry
                }
            }
            const res = await updateIndexDriveService(index_info_update, props.user.id); // TODO reload the props
            console.log('new token added', res);
            setGdToken(data)
        } catch (e) {
            console.log(e);
            toastr.error(`Something went wrong, try again`);
        }
    }

    const makeApiRequest = async (question) => {
        console.log("my question", question)

        if(question == "Open 'Job acceptance letter 2024.pdf'" || question == "Open 'Tax forms.pdf'" || question == "Open 'employment contract 2024.pdf'"){
            window.open("https://drive.google.com/file/d/1PVmPbQGtSSMrn-phNUC5twPEepTIgyca/view?usp=drive_link", '_blank');
            return;
        }

        lastQuestionRef.current = question
        error && setError(undefined)
        setIsLoading(true)
        setActiveCitation(undefined)
        setActiveAnalysisPanelTab(undefined)

        // hide qa
        setShowQa(false);

        try {
            const history = answers.map((a) => ({ user: a[0], bot: a[1].answer, sql_bot: a[1].sqlQuery }))
            const request = {
                history: [...history, { user: question, bot: undefined, route: route }],
                approach: Approaches.ReadRetrieveRead,
                overrides: {
                    prompt_template:
                        promptTemplate.length === 0 ? undefined : promptTemplate,
                    exclude_category:
                        excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    semantic_ranker: useSemanticRanker,
                    semantic_captions: useSemanticCaptions,
                    suggest_followup_questions: useSuggestFollowupQuestions,
                },
                analytics_user_id: props.user.whitelisted
                    ? props.user.id
                    : 'empty-data',
                //analytics_user_id: 'hr-index-json-2',
                index_name: props.indexName,
            }

            let response;
            let drive = props.indexObj?.drive;
            let value = props.indexObj?.value;
            console.log("INDEX OBJ ", props.indexObj)
            
            if (props.indexName === 'smart-goals') {
                console.log("smart goals")
                //make request to new endpoint
                response = await setSmartGoalsService(request);
            }
            else if (props.indexName === 'onboarding-assistant') {
                console.log("onboarding assistant")
                //make request to new endpoint
                response = await setSmartGoalsService(request);
            }
            else if (props.indexName === 'smart-goals-short') {
                response = await setSmartGoalsShortService(request)
            }
            else if (props.indexName === 'feedback-assistant') {
                response = await setFeedbackAssistantService(request)
            }
            else if (props.indexName === 'sap-times') {
                response = await setChatSapTimeService(request)
            }
            else response = await setChatHrService(request)            

            /** check the getReader*/
            console.log('RESPONSE', response);

            /// check if is stream or normal response
            const headers = [...response.headers];
            let isStream = true;
            headers.forEach((header) => {
                if (header[0] === 'content-type' && header[1] === 'application/json') {
                    isStream = false;
                }
            })

            let result = {};
            if (!isStream) {
                console.log("IS NORMAL")
                const resJson = await response.json();
                if (resJson.error_chat_google) {
                    openGoogleOauthPopUp(true)
                    throw new Error(resJson.error_chat_google);
                }

                console.log("RESSSSSSSSSss", resJson)

                const finalResponse = resJson.answer.includes('[[') && resJson.answer.includes(']]')

                result = { ...resJson, finish: true, finishInsight: true, finalResponse };
                console.log('RESPONSE JSON', result);
                setAnswers([...answers, [question, result]]);
                setIsLoading(false);
            } else {
                console.log("IS STREAM", response)
                const reader = response.body?.getReader();
                const decoder = new TextDecoder();

                let completeResponse = "";

                let obj = "";

                let haveDataPoints = false;

                while (true) {
                    setTyping(true);
                    /*console.log("SCROLLLL", contentRef.current.scrollTop, contentRef.current.scrollHeight);
                    if (contentRef.current.scrollTop === contentRef.current.scrollHeight) {

                    }*/
                    contentRef.current.scrollIntoView({ behavior: 'smooth', block: "end" })
                    // contentRef.current.scrollTop = contentRef.current.scrollHeight;
                    const { value, done: doneReading } = await reader.read();
                    if (doneReading) break;
                    let chunkValue = decoder.decode(value);

                    const haveDataStr = '[moodbit_data_points_in_comming]';
                    if (haveDataPoints)
                        obj += chunkValue;
                    else {
                        if (chunkValue.includes(haveDataStr)) {
                            haveDataPoints = true;
                            const firstPos = chunkValue.indexOf(haveDataStr);
                            completeResponse += chunkValue.substring(0, firstPos);
                            obj += chunkValue.substring(firstPos + haveDataStr.length);
                        }
                        else
                            completeResponse += chunkValue;
                    }
                    // setResponseTest(completeResponse);
                    result = { ...{}, answer: completeResponse, finish: false, finishInsight: true }
                    setAnswers([...answers, [question, { ...result, containerName: props.containerName }]])
                    setIsLoading(false)
                }
                // setTyping(false);
                if (obj === "") {
                    obj = {}
                } else {
                    obj = JSON.parse(obj);
                }

                const finalResponse = completeResponse?.includes('[[') && completeResponse?.includes(']]')
                result = { ...obj, answer: completeResponse, finish: true, finishInsight: true, finalResponse }
                setRoute(result.route)
                console.log("RESULT END", result)
            }

            setIsLoading(false)

            if (props.indexName !== 'smart-goals-short' && props.indexName !== 'smart-goals' && props.indexName !== 'feedback-assistant' && props.indexName !== 'onboarding-assistant' && props.indexName !== 'sap-times') {
                setIsLoadingInsight(true);

                /**  generate the followup questions */
                setIsLoadingFollowup(true);////
                const { followup } = await getFollowup([...history, { user: question, bot: undefined }], props.indexName);
                setIsLoadingFollowup(false);
                await readInsightsStream(result.answer, question, result, followup);
            } else {
                if(props.indexName === 'onboarding-assistant'){
                    let followup = ''
                    if(result.answer.indexOf('job acceptance') != -1 && result.answer.indexOf('signed') != -1 && result.answer.indexOf('?') != -1 && result.answer.indexOf('date') === -1){
                        followup = "<<Yes, I have already returned my signed job acceptance letter.>>\n<<No, I have not returned my signed job acceptance letter.>>"
                    }
                    else if(result.answer == "No problem! To move forward, please sign your job acceptance letter and return it to us. I'm attaching it."){
                        followup = "<<Open 'Job acceptance letter 2024.pdf'>>"
                    }
                    else if(result.answer.indexOf('W-4') != -1 && result.answer.indexOf('W-9') != -1 && result.answer.indexOf('?') != -1 && result.answer.indexOf('date') === -1){
                        followup = "<<Yes, I have completed the tax forms W-4 or W-9>>\n<<No, I have not completed the tax forms yet (i.e. W-4 or W-9)>>"
                    }
                    else if(result.answer.indexOf('No worries! To complete your tax forms') != -1){
                        followup = "<<Open 'Tax forms.pdf'>>"
                    }
                    else if(result.answer.indexOf('employment contract') != -1 && result.answer.indexOf('?') != -1 && result.answer.indexOf('legal') === -1){
                        followup = "<<Yes, I have signed and returned my employment contract.>>\n<<No, I have not yet signed and returned my employment contract.>>"
                    }
                    else if(result.answer.indexOf('No problem! To complete this step, please review, sign, and return your employment contract as soon as possible.') != -1){
                        followup = "<<Open 'employment contract 2024.pdf'>>"
                    }
                     
                    if(followup != '')
                        setAnswers([...answers, [question, { ...result, containerName: props.containerName, followup: followup}]])
                    else
                        setAnswers([...answers, [question, { ...result, containerName: props.containerName}]])
                }
                else
                    setAnswers([...answers, [question, { ...result, containerName: props.containerName }]])
            }


            // console.log("FOLLOWUP", followup);

            /**  generate the Insight for the answer if have */
            // const insight = await getInsight(result.answer);
            // console.log("INSIGHT", insight);


            setTyping(false);

            await storeMessage({
                role: 'user',
                content: question,
            })
            await storeMessage({
                ...result,
                role: 'assistant',
                content: result.answer,
            })
        } catch (e) {
            console.log('ERROR', e)
            if (typeof e === 'string' || e instanceof String) {
                e = JSON.parse(e);
                console.log('ERROR 2', e)
            }
            // setError(e.response?.data?.error)
            toastr.error(`Something went wrong, please reload the page to try again`);
            setError(e)
            setIsLoading(false)
        } finally {
            // setIsLoading(false);
        }
    }

    const clearChat = () => {
        lastQuestionRef.current = ''
        error && setError(undefined)
        setActiveCitation(undefined)
        setActiveAnalysisPanelTab(undefined)
        setAnswers([])
    }

    useEffect(
        () => chatMessageStreamEnd.current?.scrollIntoView({ behavior: 'smooth' }),
        [isLoading]
    )

    /**for update the theme*/
    useEffect(() => {
        if (props.user.copilotTheme) {
            props.setCopilotTheme(props.user.copilotTheme)
        }
    }, [])
    const onPromptTemplateChange = (e, newValue) => {
        setPromptTemplate(newValue || '')
    }

    const onRetrieveCountChange = (_ev, newValue) => {
        setRetrieveCount(parseInt(newValue || '3'))
    }

    const onUseSemanticRankerChange = (_ev, checked) => {
        setUseSemanticRanker(!!checked)
    }

    const onUseSemanticCaptionsChange = (_ev, checked) => {
        setUseSemanticCaptions(!!checked)
    }

    const onExcludeCategoryChanged = (_ev, newValue) => {
        setExcludeCategory(newValue || '')
    }

    const onUseSuggestFollowupQuestionsChange = (_ev, checked) => {
        setUseSuggestFollowupQuestions(!!checked)
    }

    const onExampleClicked = (example) => {
        makeApiRequest(example)
    }

    const onShowCitation = (citation, name, index) => {
        if (
            activeCitation === citation &&
            activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab &&
            selectedAnswer === index
        ) {
            setActiveAnalysisPanelTab(undefined)
            setFileName(null)
        } else {
            setActiveCitation(citation)
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab)
            setFileName(name)
        }
        setSelectedAnswer(index)
    }

    const onToggleTab = (tab, index) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined)
        } else {
            setActiveAnalysisPanelTab(tab)
        }

        setSelectedAnswer(index)
    }

    const newConversation = async () => {
        setLoading(true)
        const data = await storeConversationMessage({
            languageInput: language,
            analyticsUserId: props.user.id,
            type: 'store',
        })
        console.log(data)
        setHrId(data.evaluation.id)
        setLoading(false)
        setInit(false)
    }

    const goBack = (dropHr = true) => {
        if (dropHr) setHrId(null);
        lastQuestionRef.current = '';
        setAnswers([]);
        setIsLoading(false);
    }

    // console.log("ANS", answers)
    return (
        <div className={styles.container}>
            {hrId && !showQa && (
                <div className={styles.topContainer}>
                    {props.user.whitelisted ? <SelectIndex /> : null}
                    <div className={styles.btnBack}>
                        <ClearChatButton onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} />
                        <Button
                            variant="contained"
                            onClick={() => goBack()}
                        >
                            <ArrowBack fontSize="large" />
                        </Button>
                    </div>
                </div>
            )}
            <div className={styles.commandsContainer}>

            </div>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!lastQuestionRef.current ? (
                        <div className={styles.chatEmptyState}>
                            {/*<SparkleFilled fontSize={"120px"} primaryFill={"rgba(115, 118, 225, 1)"} aria-hidden="true"
                                           aria-label="Chat logo"/>*/}

                            {loading && (
                                <div>
                                    <h1 className={styles.chatEmptyStateTitle}>
                                        Welcome to Moodbit Copilot
                                    </h1>
                                    <h2 className={styles.chatEmptyStateSubtitle}>
                                        Ask any question or request, or choose from our pre-built
                                        questions, and get the answers and insights you need with
                                        ease.
                                    </h2>
                                    <p className="my-3 text-center">Starting conversation...</p>
                                </div>
                            )}
                            {!hrId && !loading && (
                                <div className="pb-2">
                                    <h1 className={styles.chatEmptyStateTitle}>
                                        Moodbit Copilot, your AI-HR agent can
                                    </h1>
                                    <div className={styles.chatEmptyStateTitleAnimated}>
                                        <Typed
                                            strings={[
                                                'show you the latest payroll report.',
                                                'help you design a career path for your teams.',
                                                'understand your employee attrition level, and give you a strategy to change it.',
                                            ]}
                                            typeSpeed={50}
                                            backSpeed={10}
                                            loop
                                        />
                                    </div>
                                    <h2 className={styles.chatEmptyStateSubtitle}>
                                        With Moodbit Copilot, you have a knowledgeable and reliable
                                        assistant at your fingertips, available to help you with any
                                        question or request you may have.
                                    </h2>
                                    <h2 className={styles.chatEmptyStateSubtitle}>
                                        Simply start a conversation with Moodbit Copilot and unlock
                                        a world of possibilities. Need to know the status of a
                                        hiring process? Want to generate a report on employee
                                        performance? Interested in exploring career paths for your
                                        team members? Just ask, and Moodbit Copilot will provide you
                                        with the answers you need, from your organization's data.
                                    </h2>
                                    <div className={styles.startButton}>
                                        <button
                                            className="btn btn-primary "
                                            onClick={() => newConversation()}
                                        >
                                            Start Now
                                        </button>
                                    </div>
                                </div>
                            )}

                            {hrId && (
                                <div className={styles.chatEmptyState}>
                                    <ExampleList onExampleClicked={onExampleClicked} indexName={props.indexName} />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className={styles.chatMessageStream} ref={contentRef}>
                            {
                                /** show pbi QA */
                                showQa ?
                                    <div>
                                        <PbiQaIndex question={currentMessageQa}
                                                    answers={answers}
                                                    setClose={() => {
                                                        setShowQa(false);
                                                        setCurrentMessageQa(null);
                                                    }} />
                                    </div>
                                    : answers.map((answer, index) => (
                                        <div key={index}>
                                            <UserChatMessage message={answer[0]} />
                                            <div className={styles.chatMessageGpt}>
                                                <Answer
                                                    key={index}
                                                    index={index}
                                                    answer={answer[1]}
                                                    answers={answers}
                                                    isLoadingInsight={isLoadingInsight}
                                                    isLoadingFollowup={isLoadingFollowup}
                                                    makeApiRequest={makeApiRequest}
                                                    isSelected={
                                                        selectedAnswer === index &&
                                                        activeAnalysisPanelTab !== undefined
                                                    }
                                                    onCitationClicked={(c, name) =>
                                                        onShowCitation(c, name, index)
                                                    }
                                                    onThoughtProcessClicked={() =>
                                                        onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)
                                                    }
                                                    onSupportingContentClicked={() =>
                                                        onToggleTab(
                                                            AnalysisPanelTabs.SupportingContentTab,
                                                            index
                                                        )
                                                    }
                                                    newFollowup={() => newFollowup(answer[0])}
                                                    onFollowupQuestionClicked={(q) => makeApiRequest(q)}
                                                    showFollowupQuestions={
                                                        useSuggestFollowupQuestions &&
                                                        answers.length - 1 === index
                                                    }
                                                    onSetShowQa={() => {
                                                        setShowQa(true)
                                                        setCurrentMessageQa(answer[0])
                                                    }}
                                                    question={answer[0]}
                                                    setClose={() => {
                                                        setShowQa(false);
                                                        setCurrentMessageQa(null);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ))
                            }

                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidthLoading}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError
                                            error={error.toString()}
                                            onRetry={() => makeApiRequest(lastQuestionRef.current)}
                                        />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}

                    {hrId && !showQa && (
                        <div className={styles.chatInput}>
                            <QuestionInput
                                clearOnSend
                                placeholder="Type a message (e.g. Show me the list of employees)"
                                disabled={isLoading || typing}
                                onSend={(question) => makeApiRequest(question)}
                            />
                        </div>
                    )}
                </div>

                {answers.length > 0 && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        fileName={fileName}
                        onActiveTabChanged={(x) => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={answers[selectedAnswer][1]}
                        activeTab={activeAnalysisPanelTab}
                    />
                )}

                {
                    <Panel
                        headerText="Configure answer generation"
                        isOpen={isConfigPanelOpen}
                        isBlocking={false}
                        onDismiss={() => setIsConfigPanelOpen(false)}
                        closeButtonAriaLabel="Close"
                        onRenderFooterContent={() => (
                            <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>
                                Close
                            </DefaultButton>
                        )}
                        isFooterAtBottom={true}
                    >
                        <TextField
                            className={styles.chatSettingsSeparator}
                            defaultValue={promptTemplate}
                            label="Override prompt template"
                            multiline
                            autoAdjustHeight
                            onChange={onPromptTemplateChange}
                        />

                        <SpinButton
                            className={styles.chatSettingsSeparator}
                            label="Retrieve this many documents from search:"
                            min={1}
                            max={50}
                            defaultValue={retrieveCount.toString()}
                            onChange={onRetrieveCountChange}
                        />
                        <TextField
                            className={styles.chatSettingsSeparator}
                            label="Exclude category"
                            onChange={onExcludeCategoryChanged}
                        />
                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={useSemanticRanker}
                            label="Use semantic ranker for retrieval"
                            onChange={onUseSemanticRankerChange}
                        />
                        <Checkbox
                            className={styles.chatSettingsSeparatr}
                            checked={useSemanticCaptions}
                            label="Use query-contextual summaries instead of whole documents"
                            onChange={onUseSemanticCaptionsChange}
                            disabled={!useSemanticRanker}
                        />
                        <Checkbox
                            className={styles.chatSettingsSeparator}
                            checked={useSuggestFollowupQuestions}
                            label="Suggest follow-up questions"
                            onChange={onUseSuggestFollowupQuestionsChange}
                        />
                    </Panel>
                }
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    console.log('state app:', state)
    return {
        user: state.users.user,
        indexName: state.agentHrs.index,
        containerName: state.agentHrs.container,
        indexObj: state.agentHrs.indexObj
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setIndexName: (index) => dispatch(setIndex(index)),
        setContainer: (container) => dispatch(setContainer(container)),
        setCopilotTheme: (theme) => dispatch(setCopilotTheme(theme))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SmartGoalsIndex)
