export function getUniqueFileTypes(arrayOfArrays) {
    const fileTypes = arrayOfArrays
        .map(subArray => {
            const fileExtension = subArray[2];
            return fileExtension.toLowerCase();
        })
        .filter((extension, index, self) => self.indexOf(extension) === index);

    return fileTypes;
}
