import {capitalize, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import {Article, Cable, Circle, GridOn, Image, KeyboardArrowRight} from "@mui/icons-material";
import {useState} from "react";

const FileIcon = (props) => {
    return (
        <>
            {props.type === 'document' && <Article fontSize="small" className="text-primary"/>}
            {props.type === 'sheet' && <GridOn fontSize="small" className="text-success"/>}
            {props.type === 'image' && <Image fontSize="small" className=""/>}
        </>
    )
}

const FilesMenu = (props) => {
    let submenuTimeout;
    const {anchorEl, handleOpen, filterDriveFiles, allFilesDrive, mimeTypes, filesByType} = props;
    const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
    const [submenuOpen, setSubmenuOpen] = useState(false);

    const handleSubmenuOpen = (event, type) => {
        filterDriveFiles(type)
        setSubmenuAnchorEl(event.currentTarget);
        setSubmenuOpen(true);
    };

    const handleSubmenuClose = () => {
        setSubmenuAnchorEl(null);
        setSubmenuOpen(false);
        handleOpen(null);
    };

    const selectFile = (fileId) => {
        if (fileId === props.driveFileId) {
            props.setDriveFileId('');
        } else {
            props.setDriveFileId(fileId);
        }
        setTimeout(() => {
            handleSubmenuClose();
        }, 500)
    }

    return (<>
        {mimeTypes.length > 0 && mimeTypes.map((type, index) =>
            <MenuItem onClick={!submenuOpen ? (event) => handleSubmenuOpen(event, type) : handleSubmenuClose} key={index}>
                <ListItemIcon>
                    {/*<Cable fontSize="small" />*/}
                    <FileIcon type={type}/>
                </ListItemIcon>
                <ListItemText>{capitalize(type || '')}</ListItemText>
                <ListItemIcon className="ps-3">
                    <KeyboardArrowRight fontSize="small" />
                </ListItemIcon>
            </MenuItem>
        )}

        {/* Submenu FILES */}
        <Menu
                    anchorEl={submenuAnchorEl}
                    open={submenuOpen}
                    onClose={handleSubmenuClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    sx={{mt: '-16px'}}
                >
            {filesByType.length > 0 && filesByType.map((file, it) =>
                <MenuItem onClick={() => selectFile(file[0])} key={it}>
                    {/*<ListItemIcon>
                            <img style={{width: '25px', height: '25px'}} src={googleDriveLogo} />
                        </ListItemIcon>*/}
                        <ListItemText>{file[1]}</ListItemText>
                    {props.driveFileId === file[0] && <ListItemIcon className="ps-3 text-success">
                        <Circle style={{fontSize: '10px'}} />
                    </ListItemIcon>}
                </MenuItem>)}
        </Menu>
    </>);
}

export default FilesMenu;
