import {useEffect, useMemo, useState} from "react";
import {Stack} from "@fluentui/react";

import styles from './answer.module.css'

import {parseAnswerToHtml, parseFollowUp} from "./answer-parser";
import {AnswerIcon} from "./answer-icon";
import {
    getCitationFilePathHR,
    setFeedbackSAPAuthService,
    setSAPAuthService,
    setSAPPostGoalService, setTimesOffSAPAuthService
} from "../../../../../services/agent-hr.service";
import {connect} from "react-redux";
import {SendEmailButton} from "../send-email-button/send-email-button";
import {
    Alert,
    AlertTitle,
    Chip,
    CircularProgress, Collapse,
    IconButton, List,
    ListItemButton,
    ListItemIcon,
    ListItemText, Tooltip
} from "@mui/material";
import {
    Assistant,
    BarChart,
    Check,
    Close, ContentCopy,
    KeyboardArrowDown,
    KeyboardArrowUp,
    PictureAsPdf, Publish,
    Save
} from "@mui/icons-material";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import './answer.css';
import PbiQaIndex from "../pbi-qa/pbi-qa-index";
import {getY} from "../../helpers/pdf";
import AnswerLoading from "./answer-loading";
import FollowupLoading from "./followup-loading";
import {toastr} from "react-redux-toastr";
import {CopyToClipboard} from "react-copy-to-clipboard";

const Answer = (props) => {
    // console.log(props.makeApiRequest)
    // console.log("ANS", document.querySelector(".answer-container"));
    const {
        answer,
        isSelected,
        onCitationClicked,
        onThoughtProcessClicked,
        onSupportingContentClicked,
        onFollowupQuestionClicked,
        showFollowupQuestions,
        newFollowup,
        index,
        isLoadingInsight,
        isLoadingFollowup
    } = props

    const [loadingSAP, setLoadingSAP] = useState(false);
    const [msgSAP, setMsgSAP] = useState(null);
    const [openDetailsSAP, setOpenDetailsSAP] = useState(false);

    /*const [width, setWidth] = useState(0);
    useEffect(() => {
        const content = document.querySelector(".answer-container");
        console.log("content", content.clientWidth, content.clientHeight);
        setWidth(content?.clientWidth);
    },[])

    useEffect(() => {
        console.log("WIDTH", width, showFollowupQuestions);
        if (!showFollowupQuestions) {
            const container = document.querySelector(".answer-container");
            container.styles.width = width;
            console.log("WIDTH SW", width);
        }
    }, showFollowupQuestions);*/
    const parsedAnswer = useMemo(
        () => parseAnswerToHtml(answer.answer, onCitationClicked, props.user, props.containerName, answer, props.chatTheme, onFollowupQuestionClicked),
        [answer]
    )

    const followupQuestions = parseFollowUp(answer);    
    // const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    const sanitizedAnswerHtml = parsedAnswer.answerHtml;

    const printPDF = async () => {
        const doc = new jsPDF();
        doc.setFontSize(10);
        const div = document.createElement('div');
        div.style.whiteSpace = "pre-line";
        div.innerHTML = sanitizedAnswerHtml;

        const table = div.querySelector('table');
        if (table) {
            const contentTable = div.querySelector('.table-content');
            let prevElements = [];
            let currentElement = contentTable.previousElementSibling;
            while (currentElement !== null) {
                prevElements.unshift(currentElement);
                currentElement = currentElement.previousElementSibling;
            }

            let nextElements = [];
            currentElement = contentTable.nextElementSibling;
            while (currentElement !== null) {
                nextElements.unshift(currentElement);
                currentElement = currentElement.nextElementSibling;
            }

            //join all in a pdf
            // await doc.html(prevElements[0],{margin: [10, 10, 10, 10], autoPaging: 'text', x: 10, y: 10, width: 150, windowWidth: 500});

            let currentY = getY(doc, prevElements.length > 0 ? prevElements[0].outerHTML : '', 15) + 10;

            doc.autoTable({html: table, startY: currentY + 10});

            currentY = getY(doc, nextElements.length > 0 ? nextElements[0].outerHTML : '', doc.autoTable.previous.finalY + 10) + 10;
            // await doc.html(nextElements[0],{margin: [10, 10, 10, 10], autoPaging: 'text', width: 150, windowWidth: 500,x: 10, y: doc.autoTable.previous.finalY + 10});
            doc.save('document');
        } else {
            doc.html(div, {
                async callback(doc) {
                    await doc.save('document');
                    div.classList.remove('page-size');
                },
                margin: [10, 10, 10, 10],
                autoPaging: 'text',
                x: 15,
                y: 15,
                width: 150, //target width in the PDF document
                windowWidth: 500 //window width in CSS pixels
            });
        }
    }

    const submitToSAP = async () => {
        if (window.confirm('Are you sure to send this information? This operation will create new records in your SAP environment')) {

            let response = null;
            setLoadingSAP(true)
            if (props.indexName === 'smart-goals' || props.indexName === 'smart-goals-short') {
                response = await submitGoalToSAP()
                if (response.error) {
                    toastr.error('SMART Goal not Saved', response?.error?.message?.value)
                } else {
                    setMsgSAP({
                        title: 'Success: Goal Saved successfully',
                        subtitle: 'These are the details of the registered goal',
                        details: [
                            {
                                title: 'User Id',
                                description: `${response.useId}`
                            },
                            {
                                title: 'Name',
                                description: `${response.name}`
                            },
                            {
                                title: 'Metric',
                                description: `${response.metric}`
                            },
                            {
                                title: 'Start Date',
                                description: `${response.start}`
                            },
                            {
                                title: 'Due Date',
                                description: `${response.due}`
                            }
                        ]
                    })
                    toastr.success('Success Goal Saved successfully')
                }
            }
            if (props.indexName === 'feedback-assistant') {
                response = await submitFeedbackToSAP()
                if (response?.error) {
                    toastr.error('Feedback not Saved', response?.error?.message?.value)
                } else {
                    const res = {
                        title: 'Success: Feedback Saved successfully',
                        subtitle: 'These are the details of the registered feedback',
                        details: [
                            {
                                title: 'Subject User UUID',
                                description: `${response.subjectUserAssignmentUUID}`
                            },
                            {
                                title: 'Sender User UUID',
                                description: `${response.senderUserAssignmentUUID}`
                            },
                            {
                                title: 'Topic',
                                description: `${response.topic}`
                            },
                        ]
                    };

                    if (response.question1 !== null && response.answer1 !== null) {
                        res.details.push({
                            title: "Question 1",
                            description: `${response.question1}`
                        })
                        res.details.push({
                            title: "Answer 1",
                            description: `${response.answer1}`
                        })
                    }

                    if (response.question2 !== null && response.answer2 !== null) {
                        res.details.push({
                            title: "Question 2",
                            description: `${response.question2}`
                        })
                        res.details.push({
                            title: "Answer 2",
                            description: `${response.answer2}`
                        })
                    }

                    if (response.question3 !== null && response.answer3 !== null) {
                        res.details.push({
                            title: "Question 3",
                            description: `${response.question3}`
                        })
                        res.details.push({
                            title: "Answer 3",
                            description: `${response.answer3}`
                        })
                    }

                    setMsgSAP(res)

                    toastr.success('Success Feedback Saved successfully')
                }
            }

            if (props.indexName === 'sap-times') {
                response = await submitTimesOffToSAP()
                if (response.error) {
                    console.log("ERROR msg", response.error);
                    /*setMsgSAP({
                        title: 'Error: Times Not Saved',
                        subtitle: response.error,
                        details: null
                    })*/
                    toastr.error('Time Off not Saved', response?.error?.message?.value)
                } else {
                    setMsgSAP({
                            title: 'Success: Times Saved successfully',
                            subtitle: 'These are the details of the registered times',
                            details: [
                                {
                                    title: 'User Id',
                                    description: `${response.userId || ''}`
                                },
                                {
                                    title: 'Start Date',
                                    description: `${response.startDate || ''}`
                                },
                                {
                                    title: 'End Date',
                                    description: `${response.endDate || ''}`
                                }
                            ]
                        })
                    toastr.success('Time Off Saved successfully')
                }
            }
            setLoadingSAP(false);
        }
    }

    const submitGoalToSAP = async () => {
        console.log("submit to SAP")
        console.log(props.answer)

        try {
            let {d} = await setSAPAuthService({"message": props.answer.answer});
            console.log("SAP AUTH ", d)
            return d;
        } catch (e) {
            return e
        }


    }

    const submitFeedbackToSAP = async () => {
        console.log("submit to SAP")
        console.log(props.answer)
        try {
            let res = await setFeedbackSAPAuthService({"message": props.answer.answer});

            console.log("SAP AUTH ", res)

            return res;
        } catch (e) {
            return e;
        }

    }

    const submitTimesOffToSAP = async () => {
        console.log("submit to SAP")
        console.log(props.answer)

        try {
            let response = await setTimesOffSAPAuthService({"message": props.answer.answer});
            const {d} = response;
            console.log("SAP AUTH ", d)
            if (d) {
                return d;
            } else {
                return response;
            }
        } catch (e) {
            return e
        }


    }

    return (
        <div>
            <Stack style={{
                background: props.chatTheme.answerBg,
                color: props.chatTheme.answerText,
            }}
                   className={`${styles.answerContainer} ${isSelected && styles.selected}`}
                   id={`answer-container-${index}`}
                   verticalAlign="space-between">
                <Stack.Item>
                    <Stack horizontal horizontalAlign="space-between">
                        <AnswerIcon/>
                        {answer.finalResponse ? <div>
                            {/*<SendEmailButton answer={answer} index={index} content={sanitizedAnswerHtml}
                                             citations={parsedAnswer.citations} user={props.user}/>
                            <IconButton
                                title="Show thought process"
                                ariaLabel="Show thought process"
                                onClick={() => printPDF()}>
                                <PictureAsPdf/>
                            </IconButton>*/}
                            {/*<IconButton
                            title="Show QA report"
                            index={props.index}
                            ariaLabel="Show QA report"
                            onClick={() => props.onSetShowQa()}>
                            <BarChart/>
                        </IconButton>*/}
                            {!loadingSAP ? <Tooltip title="Save in SAP">
                                <IconButton
                                    onClick={() => submitToSAP()}>
                                    <Publish/>
                                </IconButton>
                            </Tooltip> : <CircularProgress/>}
                            <CopyToClipboard text={parsedAnswer.finalResult}
                                             onCopy={() => toastr.success('', "Successfully copied content")}>
                                <Tooltip title="Copy Result">
                                    <IconButton>
                                        <ContentCopy fontSize="small"/>
                                    </IconButton>
                                </Tooltip>
                            </CopyToClipboard>
                        </div> : null}
                        {/*<div>
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.thoughts}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.data_points.length}
                        />
                    </div>*/}
                    </Stack>
                </Stack.Item>

                {/*answer.isPbi ?
                <Stack.Item grow>
                    <div id={`content-${props.index}`} className={`${styles.answerText} ${answer.finish ? '' : styles.answerTextCursor}`}>
                        <PbiQaIndex answers={props.answers}
                                    index={index}
                                    showFollowupQuestions={showFollowupQuestions}
                                    query={answer.qnaQuery}
                        question={props.question}
                        setClose={props.setClose}/>
                    </div>
                </Stack.Item> :*/}

                {/** SUCCESS message to SAP goals and feedbacks **/}
                {!loadingSAP && msgSAP !== null && <Stack.Item>
                    <Alert severity="success" action={
                        <div className="d-flex">
                            <IconButton onClick={() => setOpenDetailsSAP(!openDetailsSAP)}>{openDetailsSAP ?
                                <KeyboardArrowUp/> : <KeyboardArrowDown/>}</IconButton>
                            <IconButton onClick={() => setMsgSAP(null)}><Close/></IconButton>
                        </div>
                    }>
                        <AlertTitle>{msgSAP.title}</AlertTitle>
                        {msgSAP.subtitle}
                    </Alert>
                    <Collapse in={openDetailsSAP} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {msgSAP.details?.map((item) =>
                                <ListItemButton sx={{pl: 4}}>
                                    <ListItemIcon>
                                        <Check color="success"/>
                                    </ListItemIcon>
                                    <ListItemText primary={item.title} secondary={item.description}/>
                                </ListItemButton>)}
                        </List>
                    </Collapse>
                </Stack.Item>}

                <Stack.Item grow>
                    {sanitizedAnswerHtml.includes('##########') ?

                        sanitizedAnswerHtml.split('##########').map((it, index) =>
                            <div>
                                <div id={`content-${props.index}`}
                                     className={`${styles.answerText} ${answer.finish ? '' : styles.answerTextCursor}`}
                                     dangerouslySetInnerHTML={{__html: it.trim()}}/>
                                {index === 0 ?
                                    /** SUGGESTIONS/EXAMPLES**/
                                    !!parsedAnswer.examples.length && showFollowupQuestions && (
                                        <Stack.Item className="mt-3">
                                            <Stack horizontal wrap
                                                   className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`}
                                                   tokens={{childrenGap: 6}}>
                                                <div className="w-100">
                                                    <Chip icon={<Assistant style={{
                                                        color: props.chatTheme.followupText
                                                    }}/>} label="Suggestions: " style={{
                                                        cursor: "pointer",
                                                        color: props.chatTheme.followupText,
                                                        background: props.chatTheme.followupBg
                                                    }}/>
                                                </div>
                                                {/*<span className={styles.followupQuestionLearnMore}>What do you want to ask now? </span>*/}
                                                {parsedAnswer.examples.map((x, i) => {
                                                    return (
                                                        <a key={i} style={{
                                                            color: props.chatTheme.followupBg,
                                                            borderColor: props.chatTheme.followupBg
                                                        }} className={styles.followupQuestion} title={x}
                                                           onClick={() => onFollowupQuestionClicked(x)}>
                                                            {`${x}`}
                                                        </a>
                                                    );
                                                })}
                                            </Stack>
                                        </Stack.Item>
                                    )
                                    : null}
                            </div>
                        )
                        : <div id={`content-${props.index}`}
                               className={`${styles.answerText} ${answer.finish ? '' : styles.answerTextCursor}`}
                               dangerouslySetInnerHTML={{__html: sanitizedAnswerHtml}}/>
                    }
                </Stack.Item>

                {!!parsedAnswer.citations.length && answer.finish && (
                    <Stack.Item>
                        <Stack horizontal wrap tokens={{childrenGap: 5}}>
                            <span className={styles.citationLearnMore}>Citations:</span>
                            {parsedAnswer.citations.map((x, i) => {
                                const path = getCitationFilePathHR(x, props.user.id, answer.containerName);
                                return (
                                    <span>
                                    <a key={i} className={styles.citation} title={x}
                                       onClick={() => onCitationClicked(path, x)}>
                                        {`${++i}. ${x}`}
                                    </a>
                                        {/*<a key={-i} className={styles.citation} title={'See more...'}
                                    onClick={() => props.makeApiRequest('Please answer my previous question in more detail.')}>
                                        {'See more...'}
                                    </a>}
                                    <br></br>*/}
                                </span>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                )}


            </Stack>

            {/** INSIGHTS**/}
            {(answer.insight || isLoadingInsight) &&
                <Stack.Item grow style={{
                    background: props.chatTheme.answerBg,
                    color: props.chatTheme.answerText,
                }}
                            className={styles.insights}>

                    {!answer.insight && <AnswerLoading/>}

                    {answer.insight && <h4 className="text-primary"><b>INSIGHTS</b></h4>}
                    <div
                        className={`${styles.answerText} ${styles.insightText} ${answer.finishInsight ? '' : styles.answerTextCursor}`}>{answer.insight}</div>
                </Stack.Item>}
            
            {/** FOLLOWUP QUUESTIONS**/}
            {isLoadingFollowup && <FollowupLoading/>}
            {!!followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item className="mt-3">
                    <Stack horizontal wrap
                           className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`}
                           tokens={{childrenGap: 6}}>
                        <div className="w-100">
                            <Chip icon={<Assistant style={{
                                color: props.chatTheme.followupText
                            }}/>} label="Next steps" style={{
                                cursor: "pointer",
                                color: props.chatTheme.followupText,
                                background: props.chatTheme.followupBg
                            }} onClick={newFollowup}/>
                        </div>
                        {/*<span className={styles.followupQuestionLearnMore}>What do you want to ask now? </span>*/}
                        {followupQuestions.map((x, i) => {
                            return (
                                <a key={i} style={{
                                    color: props.chatTheme.followupBg,
                                    borderColor: props.chatTheme.followupBg
                                }} className={styles.followupQuestion} title={x}
                                   onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
        </div>
    );
};


const mapStateToProps = (state) => {
    console.log('state app:', state)
    return {
        user: state.users.user,
        containerName: state.agentHrs.container,
        indexName: state.agentHrs.index,
        chatTheme: state.agentHrs.chatTheme
    }
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Answer)
