import {Avatar, Tooltip} from "@mui/material";
import wordpressLogo from "../../../../assets/Wordpress_Blue_logo.png";
import linkedinLogo from "../../../../assets/Linkedin_blue_logo.png";
import tiktokLogo from "../../../../assets/TikTokLogo.png";
import instagramLogo from "../../../../assets/Instagram_icon.png";
import twitterLogo from "../../../../assets/Twitter_icon.png";
import sharepointLogo from "../../../../assets/SharePoint-Logo.wine.png";
import {
    getAllPostaisWithScheduleThunk,
    getSinglePostaiWithScheduleThunk,
    updateSinglePostThunk
} from "../../../../redux/post-ai/post-ai.thunk";
import {updatePost} from "../../../../redux/post-ai/post-ai.actions";
import {connect} from "react-redux";

const PlatformIcon = (props) => {
    const {platform, sz, currentPost} = props;
    // console.log("PLATFORM: ", currentPost)
    const facebookLogo = 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg';
    return (
        <span>
            {platform === 'wordpress' &&
                <Tooltip title={props.user.analytics_wordpress_integrations[0]?.blogUrl}>
                    <Avatar sx={{width: sz, height: sz}} alt="wordpress" src={wordpressLogo}/>
                </Tooltip>
                    }
            {platform === 'facebook' &&
                <Tooltip title={props.currentPost?.typeUserInfo?.page_id}>
                    <Avatar sx={{width: sz, height: sz}} alt="facebook" src={facebookLogo}/>
                </Tooltip>}
            {platform === 'linkedin' &&
                <Tooltip title={props.currentPost?.typeUserInfo?.user_sub}>
                    <Avatar sx={{width: sz, height: sz}} alt="linkedin" src={linkedinLogo}/>
                </Tooltip>}
            {platform === 'tiktok' &&
                <Tooltip title={props.user.analytics_tiktok_integrations[0]?.user_name}>
                    <Avatar sx={{width: sz, height: sz}} alt="tiktok" src={tiktokLogo}/>
                </Tooltip>}
            {platform === 'instagram' &&
                <Tooltip title={props.user.analytics_instagram_integrations[0]?.user_id}>
                    <Avatar sx={{width: sz, height: sz}} alt="instagram" src={instagramLogo}/>
                </Tooltip>}
            {platform === 'twitter' &&
                <Tooltip title={props.user.analytics_twitter_integrations[0]?.user_name}>
                    <Avatar sx={{width: sz, height: sz, background: '#000'}} alt="twitter" src={twitterLogo}/>
                </Tooltip>}
            {platform === 'sharepoint' &&
                <Tooltip title={props.user.analytics_sharepoint_integrations[0]?.email}>
                    <Avatar sx={{width: sz, height: sz}} alt="sharepoint" src={sharepointLogo}/>
                </Tooltip>}
        </span>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.users.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlatformIcon);
