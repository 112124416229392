import axios from "axios";

export async function setNewWordpressIntegrationService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/wordpress/newIntegration`, obj);
    return data;
}

export async function disconnectWordpressIntegrationService(user_id) {
    const {data} = await axios.get(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/wordpress/disconnect/${user_id}`);
    return data;
}

export async function validateWordpressCredentialsService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_BACK_AI_POST}/validate-wordpress-credentials`, obj);
    return data;
}

export async function validateWordpressBlogUrlService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_BACK_AI_POST}/validate-wordpress-blogurl`, obj);
    return data;
}

export async function setNewFacebookIntegrationService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/facebook/newIntegration`, obj);
    return data;
}

export async function setNewLinkedinIntegrationService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/linkedin/newIntegration`, obj);
    return data;
}

export async function disconnectFacebookIntegrationService(user_id) {
    const {data} = await axios.get(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/facebook/disconnect/${user_id}`);
    return data;
}

export async function setNewInstagramIntegrationService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/instagram/newIntegration`, obj);
    return data;
}

export async function disconnectLinkedinIntegrationService(user_id) {
    const {data} = await axios.get(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/linkedin/disconnect/${user_id}`);
    return data;
}

export async function disconnectSharepointIntegrationService(user_id) {
    const {data} = await axios.get(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/sharepoint/disconnect/${user_id}`);
    return data;
}

export async function setNewTikTokIntegrationService(integration_id, analytics_user_id) {
    const {data} = await axios.put(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/tiktok/${integration_id}`, {'analyticsUserId': analytics_user_id});
    return data;
}

export async function setNewTwitterIntegrationService(integration_id, analytics_user_id) {
    const {data} = await axios.put(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/twitter/${integration_id}`, {'analyticsUserId': analytics_user_id});
    return data;
}

export const setNewSharepointIntegrationService = async (token, obj) => {
    const {data} = await axios.post(`${process.env.REACT_APP_BACK_AI_POST}/sharepoint/store-refreshtoken`, obj, {
        headers: {
            'authorization': `Bearer ${token}`,
        }
    });
    return data;
}

export async function disconnectTikTokIntegrationService(integration_id) {
    const {data} = await axios.delete(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/tiktok/${integration_id}`);
    return data;
}

export async function disconnectTwitterIntegrationService(integration_id) {
    const {data} = await axios.delete(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/twitter/${integration_id}`);
    return data;
}

export async function disconnectInstagramIntegrationService(user_id) {
    const {data} = await axios.get(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/instagram/disconnect/${user_id}`);
    return data;
}

export async function sendPostNowService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_BACK_AI_POST}/send_post_now`, obj);
    return data;
}

/** SCHEDULES*/

export async function setNewScheduleService(obj) {
    // const {data} = await axios.post(`${process.env.REACT_APP_POST_SCHEDULE_URL}/api/orchestrators/NewSchedule`, obj);
    const {data} = await axios.post(`${process.env.REACT_APP_POST_SCHEDULE_URL}/api/orchestrators/NewDateSchedule`, obj);
    return data;
}

export async function deleteScheduleWebhookService(scheduleId, reason) {
    return await axios.post(`${process.env.REACT_APP_POST_SCHEDULE_URL}/runtime/webhooks/durabletask/instances/${scheduleId}/terminate?reason=${reason}&taskHub=postaischedule&connection=Storage&code=gpY0wNqR1DAMKyH7IKk5daB-n07r7XMp3RD0s57g7H52AzFuBEtFTw==`);
}

export const addSchedulePostAIService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/post-ai-schedule/newSchedule`, obj);
    return data;
}

export const getAllPostaisWithScheduleService = async (userId) => {
    const { data } = await axios.get(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/post-ai/allPosts/${userId}`);
    return data;
}

export const getSinglePostaiService = async (postId) => {
    const { data } = await axios.get(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/post-ai/getPost/${postId}`);
    return data;
}

export const updateSinglePostaiService = async (obj, postId) => {
    const { data } = await axios.put(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/post-ai/editPost/${postId}`, obj);
    return data;
}

export const editSchedulePostAIService = async (id) => {
    const { data } = await axios.put(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/post-ai-schedule/editSchedule/${id}`, {
        state: false
    });
    return data;
}

export const deleteSchedulePostAIService = async (id) => {
    const { data } = await axios.delete(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/post-ai-schedules/deleteSchedule/${id}`);
    return data;
}


/*** MONTH SCHEDULE */
export async function createMonthService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_BACK_AI_POST}/create_schedule`, obj);
    return data;
}

export async function createPostMonthService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_BACK_AI_POST}/create_post_month`, obj);
    return data;
}

export async function newTopicPostMonthService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_BACK_AI_POST}/get_topic_post_month`, obj);
    return data;
}

export async function getAllMonthPostService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_BACK_AI_POST}/get_all_monthly_post`, obj);
    return data;
}

export async function getAllMonthPostPerUserService(userId) {
    const {data} = await axios.get(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/post-monthly/all-posts/${userId}`);
    return data;
}

///get all plans
export async function getAllPlansMonthPostService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/post-monthly/all-plans`, obj);
    return data;
}

export async function getSinglePlanMonthPostService(id) {
    const {data} = await axios.get(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/post-monthly/get-plan/${id}`);
    return data;
}

export async function getSingleMonthPostService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_BACK_AI_POST}/get_single_monthly_post`, obj);
    return data;
}

export async function updateSingleMonthPostService(obj, id) {
    const {data} = await axios.put(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/post-monthly/${id}`, obj);
    return data;
}

export async function updateImageMonthPostService(id) {
    const {data} = await axios.post(`${process.env.REACT_APP_BACK_AI_POST}/update_image`, {post_id: id});
    return data;
}

export async function updatedStatusMonthService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_BACK_AI_POST}/validate-wordpress-credentials`, obj);
    return data;
}

export async function setNewSinglePostScheduleService(obj) {
    const {data} = await axios.post(`${process.env.REACT_APP_POST_SCHEDULE_URL}/api/orchestrators/MonthSchedule`, obj);
    return data;
}

export const addScheduleMonthPostService = async (obj) => {
    const { data } = await axios.post(`${process.env.REACT_APP_SURVEY_BACKEND_URL}/api/analytics/post-ai-schedule/newSchedule`, obj);
    return data;
}
